import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const ListHeaderContainer = styled.div`
  font-family: Univers-LT-Bold;
  margin-top: 12px;
  margin-bottom: 12px;
  border-left: 4px solid transparent;

  & span {
    display: inline-block;
    vertical-align: top;
    padding-left: 0px;
  }
`;
const WidthHolderRoundCheck = styled.span`
  width: 12px;
  margin-left: 20px;
`;
const WidthHolderSpan = styled.span`
  margin-left: 14px;
`;
const WidthHolderBtn = styled.span`
  width: 30px;
`;

export default (props) => {
  const fixWidthRatio = props.buttonAreaWidth / window.innerWidth;
  const showCheckbox = props.showCheckbox;
  return (
    <ListHeaderContainer>
      {props.cfgs.map( (cfg, i) => {
        //return(<span key={i}>{cfg.title.split("\n").map((item, key) => { return <div key={key}>{item}</div>; })}</span>);
        switch (cfg.type){
          case 'checkbox':
            return (showCheckbox ?  <WidthHolderRoundCheck key={i}></WidthHolderRoundCheck> : <WidthHolderSpan key={i} ></WidthHolderSpan>);
          case 'editbtn':
            return (<WidthHolderBtn key={i}></WidthHolderBtn>);
          case 'deletebtn':
            return (<WidthHolderBtn key={i}></WidthHolderBtn>);
          case 'custombtn':
            return (<WidthHolderBtn key={i}></WidthHolderBtn>);
          case 'docketbtn':
            return (<WidthHolderBtn key={i}></WidthHolderBtn>);
          case 'packingbtn':
            return (<WidthHolderBtn key={i}></WidthHolderBtn>);
          case 'picklistbtn':
            return (<WidthHolderBtn key={i}></WidthHolderBtn>);
          case 'summarybtnbtn':
            return (<WidthHolderBtn key={i}></WidthHolderBtn>);
          case 'resetbtn':
            return (<WidthHolderBtn key={i}></WidthHolderBtn>);
          case 'm_sales':
            return (<span key={i} style={{width: cfg.widthRatio * (1 - fixWidthRatio) * 100 + '%'}}>{moment().subtract(cfg.m, 'months').format('MM/YY')} Sales</span>);
          case 'm_ave_sales':
            return (<span key={i} style={{width: cfg.widthRatio * (1 - fixWidthRatio) * 100 + '%'}}>{moment().subtract(cfg.mn, 'months').format('MM/YY')}-{moment().subtract(cfg.ms, 'months').format('MM/YY')} Ave Sales</span>);  
          default:
            const width = {width: cfg.widthRatio * (1 - fixWidthRatio) * 100 + '%'};
            return(<span key={i} style={width}>{cfg.title.split("\n").map((item, key) => { return <div key={key}>{item}</div>; })}</span>);
        }
      })}
    </ListHeaderContainer>
  );
}